import * as React from "react"

import {
  Content,
  ImageWrapper,
  YouTubeContent,
  VideoWrapper,
} from "./sliceSections"

import styles from "./sliceZone.module.scss"

const SliceZone = ({ allSlices }) => {
  if (!allSlices) return null

  const slice = allSlices.map((s, index) => {
    switch (s.slice_type) {
      case "paragraph":
        return (
          <div className={styles.slices} key={index}>
            {s.primary.paragraph_image && (
              <ImageWrapper
                imageData={s.primary.paragraph_image}
                caption={s.primary.image_info.text}
              />
            )}
            {s.primary.youtube_link && s.primary.youtube_link.html && (
              <YouTubeContent
                html={s.primary.youtube_link.html}
                height={s.primary.youtube_link.height}
              />
            )}
            {s.primary.video && s.primary.video.url && (
              <VideoWrapper src={s.primary.video.url} />
            )}
            {s.primary.content && s.primary.content.html && (
              <Content key={s.id} html={s.primary.content.html} />
            )}
          </div>
        )
      case "video":
        return <VideoWrapper src={s.primary.video.url} />
      default:
        console.warn(`No support for slice type ${s.slice_type}`)
        return null
    }
  })

  return <>{slice}</>
}

export default SliceZone
