import * as React from "react"

import Layout from "@components/layout/layout"
import Article from "@components/article"
import GlowBackground from "@components/elements/glowBackground"

const ArticlePage = (data: any) => {
  const {
    meta_title,
    meta_description,
    meta_images,
    title,
    published,
    body,
    related,
    backCta,
  } = data.pageContext

  return (
    <Layout
      title={meta_title || title.text}
      description={meta_description}
      metaImage={meta_images[0]?.image?.url}
    >
      <GlowBackground>
        <Article
          title={title.text}
          published={published}
          body={body}
          related={related}
          backCta={backCta}
        />
      </GlowBackground>
    </Layout>
  )
}

export default ArticlePage
