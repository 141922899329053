import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player/lazy"
import cx from "classnames"

import Wysiwyg from "@components/elements/wysiwyg"

import CrossIcon from "@images/cross.inline.svg"

import styles from "./sliceZone.module.scss"

export const Content = ({ html }) => {
  return <Wysiwyg contentHtml={html} className={styles.content} />
}

export const ImageWrapper = ({ imageData, caption }) => {
  const image = getImage(imageData)

  if (!(image || caption)) return

  return (
    <div className={styles.imageWrapper}>
      {image && <GatsbyImage className={styles.picture} image={image} alt="" />}
      {caption && <div className={styles.caption}>{caption}</div>}

      <CrossIcon className={cx(styles.icon, styles.topLeft)} />
      <CrossIcon className={cx(styles.icon, styles.topRight)} />
      <CrossIcon className={cx(styles.icon, styles.bottomLeft)} />
      <CrossIcon className={cx(styles.icon, styles.bottomRight)} />
    </div>
  )
}

export const VideoWrapper = ({ src }) => {
  return (
    <div className={styles.imageWrapper}>
      <ReactPlayer className={styles.videoPlayer} url={src} controls={true} />

      <CrossIcon className={cx(styles.icon, styles.topLeft)} />
      <CrossIcon className={cx(styles.icon, styles.topRight)} />
      <CrossIcon className={cx(styles.icon, styles.bottomLeft)} />
      <CrossIcon className={cx(styles.icon, styles.bottomRight)} />
    </div>
  )
}

export const YouTubeContent = ({ html }) => {
  return <Wysiwyg contentHtml={html} className={styles.youtubeContent} />
}
