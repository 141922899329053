import * as React from "react"

import Container from "@components/elements/container"
import SliceZone from "@components/sliceZone/sliceZone"
import Cta, { CtaProps } from "@components/elements/cta"

import styles from "./article.module.scss"

interface ArticleProps {
  title: string
  published?: string
  body?: string
  related?: Array<CtaProps>
  backCta?: CtaProps
}

const Article = ({
  title,
  published,
  body,
  related,
  backCta,
}: ArticleProps) => (
  <article className={styles.article}>
    <Container className={styles.container} isNarrow>
      <Cta {...backCta} className={styles.backCta} arrowLeft />

      <h1 className={styles.title}>{title}</h1>
      {published && <div className={styles.date}>{published}</div>}

      <SliceZone allSlices={body} />

      {related?.length > 0 && related[0]?.link && related[0].text && (
        <div className={styles.moreContent}>
          <h2 className={styles.moreContentTitle}>FURTHER READING</h2>

          {related.map(({ link: { url }, text }) => (
            <Cta url={url} text={text} key={url} />
          ))}
        </div>
      )}
    </Container>
  </article>
)

export default Article
